import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import AuthenticationChecker from "./AuthenticationChecker";
import { ConfigProvider } from "./providers/configProvider/ConfigProvider";
import Auth0Provider from "./providers/authProvider/auth0Provider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <ConfigProvider>
      <Auth0Provider>
        <AuthenticationChecker />
      </Auth0Provider>
    </ConfigProvider>
  </QueryClientProvider>
);

export default App;
