import React, { FC, PropsWithChildren } from "react";
import axios, { AxiosHeaders, AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import useConfig from "./providers/configProvider/useConfig";
import { IAppKey } from "./interfaces/types/axiosClient.types";

const AxiosClient: FC<PropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { config } = useConfig();
  const data: IAppKey = {
    MemberVenueId: config?.venueId || 0,
    LocalTimeZone: "Pacific Standard Time",
  };

  const appKey: string = btoa(JSON.stringify(data));

  axios.defaults.baseURL = config?.API_URI;

  axios.interceptors.request.use(async (axiosConfig): Promise<InternalAxiosRequestConfig> => {
    const token: string = await getAccessTokenSilently();

    const headers: AxiosRequestHeaders = new AxiosHeaders({
      Authorization: `Bearer ${token}`,
      appKey,
    });

    const newConfig: InternalAxiosRequestConfig = {
      ...axiosConfig,
      headers,
    };

    return newConfig;
  });

  return <>{children}</>;
};

export default AxiosClient;
