import { useContext } from "react";

import { ConfigContext } from "./ConfigProvider";
import { IApiDataType } from "../../types/apiData.type";

const useConfig = (): IApiDataType => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error("useConfig must be used within a provider");
  }

  return context;
};

export default useConfig;
