import React, { FC, PropsWithChildren } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

import useConfig from "../configProvider/useConfig";

const Auth0ProviderWithHistory: FC<PropsWithChildren> = ({ children }) => {
  const { config } = useConfig();

  if (!(config?.AUTH0_DOMAIN && config.AUTH0_CLIENT_ID)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        responseType: "token",
        audience: "https://space-needle.us.auth0.com/api/v2/",
        scope: "read:users read:user edit:user read:current_user openid offline_access",
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
