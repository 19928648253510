import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useQuery } from "react-query";
import axios from "axios";

import { IApiDataConfig, IApiDataDto, IApiDataState, IMemberVenueData, VenueEnum } from "../../types/apiData.type";
import { api } from "../../api";

const useConfigState = (): IApiDataState => {
  const [config, setConfig] = useState<IApiDataConfig | null>(null);

  const [{}, getConfig] = useAxios<IApiDataDto>(
    {
      url: api.config,
      method: "get",
    },
    { manual: true },
  );

  const {} = useQuery<IMemberVenueData>(
    "member-venue",
    () => axios.get(`${api.venueAbbreviation}${config?.venueId}`).then((res) => res.data),
    {
      enabled: !!config?.venueId && !config.abbreviation,
      onSuccess: (res) => {
        setConfig((currentConfig) => (currentConfig ? { ...currentConfig, abbreviation: res.abbreviation } : null));
      },
    },
  );

  const setVenueData = (venueId?: number, venue?: VenueEnum) => {
    setConfig((currentConfig) => (currentConfig ? { ...currentConfig, venueId, venue } : null));
  };

  useEffect(() => {
    getConfig().then((response) => {
      setConfig({
        API_URI: response.data.API_URI,
        AUTH0_CLIENT_ID: response.data.REACT_APP_AUTH0_CLIENT_ID,
        AUTH0_DOMAIN: response.data.REACT_APP_AUTH0_DOMAIN,
        REPORTS_URI: response.data.REPORTS_URI,
      });
    });
  }, []);

  return {
    config,
    setVenueData,
  };
};

export default useConfigState;
