export interface IApiDataDto {
  API_URI: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REPORTS_URI: string;
}

export enum VenueEnum {
  sn = "sn",
  cgg = "cgg",
}

export interface IVenueIdConfig {
  [key: number]: VenueEnum;
}

export const VenueIdConfig: IVenueIdConfig = {
  41: VenueEnum.sn,
  44: VenueEnum.cgg,
};

export enum VenueNameEnum {
  sn = "Space Needle",
  cgg = "Chihuly Garden and Glass",
}

export interface IApiDataConfig {
  API_URI: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  REPORTS_URI: string;
  abbreviation?: string;
  venue?: VenueEnum;
  venueId?: number;
}

export interface IApiDataState {
  config: IApiDataConfig | null;
  setVenueData: (venueId?: number, venue?: VenueEnum) => void;
}

export type IApiDataType = IApiDataState;

export interface IMemberVenueData {
  memberVenueId: number;
  abbreviation: string;
}
