import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
      dark: "#0075ff",
    },
    secondary: {
      light: "#f9f8f2",
      main: "#e7e7e7",
      dark: "#f3f3f3",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          fontFamily: "Roboto, sans-serif",
        },
      },
    },
  },
});

export default theme;
