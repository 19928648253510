import React, { FC, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Loader from "@/components/loader";
import theme from "@/theme/theme";
import AxiosClient from "@/AxiosClient";

const Home = lazy(() => import("@/pages/home"));
const Validation = lazy(() => import("@/pages/validation"));

const AppRoutingModule: FC = () => (
  <Suspense fallback={<Loader isAbsolute isHeightFull isWidthFull />}>
    <CssBaseline />
    <AxiosClient>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/validation" element={<Validation />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AxiosClient>
  </Suspense>
);

export default AppRoutingModule;
