import React, { FC, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Loader from "./components/loader";
import AppRoutingModule from "./pages/AppRoutingModule";
import useConfig from "./providers/configProvider/useConfig";
import { VenueIdConfig } from "./types/apiData.type";

const AuthenticationChecker: FC = () => {
  const { isLoading, getAccessTokenSilently, loginWithRedirect, isAuthenticated, user } = useAuth0();
  const { setVenueData, config } = useConfig();

  const checkUserLogin = () => {
    if (!user && !isLoading && !isAuthenticated) {
      getAccessTokenSilently().catch(() => {
        loginWithRedirect({ appState: { returnTo: window.location.href } });
      });
    }

    const venueId: number | undefined = user?.["https://guestx.com/app_metadata"]?.venueId;

    if (venueId) {
      setVenueData(venueId, VenueIdConfig[venueId]);
    }
  };

  useEffect(() => {
    checkUserLogin();
  }, [user, isLoading, isAuthenticated]);

  return !(user && isAuthenticated && config?.venueId) ? (
    <Loader isAbsolute isHeightFull isWidthFull />
  ) : (
    <AppRoutingModule />
  );
};

export default AuthenticationChecker;
